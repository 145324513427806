<template>
  <div class="status_panel" :style="panelStyle">
    <p class="item">
      <el-badge v-if="monitorData.insuranceJQXExpireCount != 0" :value="monitorData.insuranceJQXExpireCount">
        <el-button icon="el-icon-s-opportunity" size="small" type="danger" @click="panelClick({ jqxInsuranceState: 23 })">交强险</el-button>
      </el-badge>
      <el-button v-else icon="el-icon-s-opportunity" size="small" type="success">交强险</el-button>
    </p>
    <p class="item">
      <el-badge v-if="monitorData.insuranceSYXExpireCount != 0" :value="monitorData.insuranceSYXExpireCount">
        <el-button icon="el-icon-s-opportunity" size="small" type="danger" @click="panelClick({ syxInsuranceState: 23 })">商业险</el-button>
      </el-badge>
      <el-button v-else icon="el-icon-s-opportunity" size="small" type="success">商业险</el-button>
    </p>
    <p class="item">
      <el-badge v-if="monitorData.yearlyInspectExpireCount != 0" :value="monitorData.yearlyInspectExpireCount">
        <el-button icon="el-icon-s-opportunity" size="small" type="danger" @click="panelClick({ yearlyInspectState: 23 })">年 检</el-button>
      </el-badge>
      <el-button v-else icon="el-icon-s-opportunity" size="small" type="success">年 检</el-button>
    </p>
    <p class="item">
      <el-badge v-if="monitorData.fenceWarnCount != 0" :value="monitorData.fenceWarnCount">
        <el-button icon="el-icon-s-opportunity" size="small" type="danger" @click="panelClick({ fenceType: 'OUT' })">警 报</el-button>
      </el-badge>
      <el-button v-else icon="el-icon-s-opportunity" size="small" type="success">警 报</el-button>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    monitorData: {
      type: Object,
      default: null
    },
    right: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      panelStyle: {}
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.panelStyle = {
        right: this.right + 20 + 'px'
      }
    },
    panelClick(data) {
      this.$site.showCarList(Object.assign({ noStatus7: true, carStatus: 1 }, data))
    }
  }
}
</script>

<style lang="less" scoped>
.status_panel {
  position: absolute;
  top: 10px;
  right: 400px;
  p.item {
    float: right;
    margin-right: 20px;
  }
}
</style>
