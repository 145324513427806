<template>
  <section class="l-content full relative">
    <l-amap0 ref="map" :map-style="mapStyle" :center="mapCenter" @complete="mapComplete" @rended="mapRended" :markers="markerList" @markerClick="mapMarkerClick"></l-amap0>
    <chart-container v-if="monitorData" :monitor-data="monitorData" @gChange="groupChange"></chart-container>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import LAmap0 from '@/lgh/components/amap0/'
import ChartContainer from './monitor/chartContainer'
export default {
  computed: {
    ...mapState(['userInfo', 'setting'])
  },
  name: 'CarMonitor',
  components: { 'l-amap0': LAmap0, 'chart-container': ChartContainer },
  data() {
    return {
      mapStyle: 'amap://styles/grey',
      mapCenter: [118.787975, 31.983388],
      monitorData: null,
      condition: { groupId: null },
      markerList: []
    }
  },
  created() {
    if (this.setting.center_lng) {
      this.mapCenter = [this.setting.center_lng, this.setting.center_lat]
    }
  },
  methods: {
    mapComplete(map) {
      this.getMonitorData()
    },
    mapRended(map) {
      // map.setFitView()
    },
    mapMarkerClick(data) {
      if (data && data.carId) {
        this.$site.view_car_detail(data.carId)
      }
    },
    groupChange(value) {
      this.condition.groupId = value
      this.getMonitorData()
    },
    async getMonitorData() {
      this.monitorData = await this.$lgh.get('api/enterprise/car/monitor', ['enterpriseCode', this.condition])
      this.setMarkList()
    },
    setMarkList() {
      this.markerList = this.monitorData.carList
        .filter(p => p.lng !== null)
        .map(item => {
          return {
            data: item,
            position: new AMap.LngLat(item.lng, item.lat),
            icon: {
              type: 'image',
              image: this.$constant.getCarIcon(item.fenceType === 'OUT' ? 4 : 1),
              size: [31.5, 38],
              anchor: 'bottom-center'
            },
            text: {
              content: item.carNo,
              direction: 'right',
              zooms: [13, 20],
              offset: [-45, 30],
              style: {
                fontSize: 10,
                fontWeight: 'normal',
                fillColor: '#ffffff',
                borderColor: 'rgb(232,124,37)',
                borderWidth: 2,
                backgroundColor: 'rgba(232,124,37,.8)'
              }
            },
            call: marker => {
              marker.on('mouseover', e => {
                var _content = this.getTipContent(item)
                this.$refs.map.showTipMarker({
                  position: [item.lng, item.lat],
                  content: _content,
                  option: { width: 300, height: _content.length * 21, y: -15, labelWidth: '50px' }
                })
              })
              marker.on('mouseout', e => {
                this.$refs.map.hideTipMarker()
              })
            }
          }
        })
    },
    getTipContent(item) {
      var arr = [
        { label: '车牌号', text: item.carNo },
        { label: '车型', text: item.modelName || '' },
        { label: 'VIN码', text: item.vin || '' },
        { label: '车辆来源', text: item.carSourceText },
        { label: '车辆状态', text: item.carStatusText },
        { label: '位置状态', text: item.fenceDesc },
        { label: 'GPS时间', text: item.gpsTime }
      ]
      if (item.beatTime) {
        arr.push({ label: '心跳时间', text: item.beatTime })
      }
      if (item.electricity) {
        arr.push({ label: '剩余电量', text: item.electricity + '%' })
      }
      return arr
    }
  }
}
</script>
<style lang="less" scoped>
.m_block {
  position: relative;
  .brandlogo {
    position: absolute;
    right: 8px;
    top: 0px;
    height: 80px;
    width: 80px;
  }
}
</style>
