<template>
  <div>
    <chart v-for="(item, index) in chartList" :key="index" :chartName="item.name" :chartType="item.type" :chartStyle="item.style" :chartData="item.data" @click="chartClick"></chart>
    <status-panel v-if="chartUnit" :monitor-data="monitorData" :right="chartUnit"></status-panel>
    <l-absolute v-if="chartUnit" :left="chartUnit + 15">
      <l-select v-model="groupId" all-label="所有分组" :data="groupList" :prop="['groupId', 'groupName']" @change="groupChange"></l-select>
    </l-absolute>
  </div>
</template>

<script>
import Chart from './chart'
import StatusPanel from './statusPanel'
export default {
  props: {
    monitorData: {
      type: Object,
      default: null
    }
  },
  components: { chart: Chart, 'status-panel': StatusPanel },
  data() {
    return {
      chartUnit: null,
      chartList: [],
      groupId: null,
      groupList: []
    }
  },
  mounted() {
    this.getGroupList()
    this.init()
  },
  watch: {
    monitorData() {
      this.chartList = []
      setTimeout(() => {
        this.init()
      }, 50)
    }
  },
  methods: {
    async getGroupList() {
      this.groupList = await this.$lgh.get('api/enterprise/ecar/group/list', ['enterpriseCode'])
    },
    groupChange() {
      this.$emit('gChange', this.groupId)
    },
    init() {
      this.chartUnit = (document.documentElement.clientHeight - 60 - 5 * 4) / 3
      // 左1
      this.chartList.push({
        name: 'carSource',
        type: 'pie',
        style: {
          left: '5px',
          top: '5px',
          height: this.chartUnit + 'px',
          width: this.chartUnit + 'px'
        },
        data: {
          title: '车辆来源',
          pie: {
            radius: '50%',
            roseType: 'area'
          },
          data: this.monitorData.carSourceData
        }
      })
      // 左2
      this.chartList.push({
        name: 'carStatus',
        type: 'pie',
        style: {
          left: '5px',
          top: this.chartUnit + 5 * 2 + 'px',
          height: this.chartUnit + 'px',
          width: this.chartUnit + 'px'
        },
        data: {
          title: '车辆状态',
          pie: {
            radius: ['20%', '55%'],
            roseType: 'area'
          },
          data: this.monitorData.carStatusData
        }
      })
      // 右1
      this.chartList.push({
        name: 'employeeLicense',
        type: 'pie',
        style: {
          right: '5px',
          top: '5px',
          height: this.chartUnit + 'px',
          width: this.chartUnit + 'px'
        },
        data: {
          title: '使用状态',
          color: ['rgb(193,46,52)', 'rgb(230,182,0)', 'rgb(43,130,29)'],
          pie: {
            radius: '50%',
            roseType: 'radius'
          },
          // data: [
          //   { name: '正常', value: 18 },
          //   { name: '即将到期', value: 4 },
          //   { name: '已过期', value: 1 }
          // ]
          data: this.monitorData.useStatusData
        }
      })
      // 右2
      this.chartList.push({
        name: 'carInspect',
        type: 'pie',
        style: {
          right: '5px',
          top: this.chartUnit + 5 * 2 + 'px',
          height: this.chartUnit + 'px',
          width: this.chartUnit + 'px'
        },
        data: {
          title: '车辆检查',
          color: ['rgb(153,153,153)', 'rgb(43,130,29)', 'rgb(230,182,0)', 'rgb(193,46,52)'],
          pie: {
            radius: ['20%', '55%'],
            roseType: 'radius'
          },
          data: this.monitorData.inspectData
        }
      })
      // 左3
      this.chartList.push({
        name: 'insurance',
        type: 'bar',
        style: {
          left: '5px',
          bottom: '5px',
          height: this.chartUnit + 'px',
          width: (document.documentElement.clientWidth - 210 - 15) / 2 + 'px'
        },
        data: {
          title: '保险监控',
          color: ['rgb(43,130,69)', 'rgb(230,182,50)', 'rgb(193,46,92)', 'rgb(43,130,29)', 'rgb(230,182,0)', 'rgb(193,46,52)'],
          data: this.monitorData.insuranceData
        }
      })
      // 右3
      this.chartList.push({
        name: 'yearlyInspect',
        type: 'bar',
        style: {
          right: '5px',
          bottom: '5px',
          height: this.chartUnit + 'px',
          width: (document.documentElement.clientWidth - 210 - 15) / 2 + 'px'
        },
        data: {
          title: '年检监控',
          color: ['rgb(43,130,29)', 'rgb(230,182,0)', 'rgb(193,46,52)'],
          data: this.monitorData.yearlyInspectData
        }
      })
    },
    chartClick(a, b) {
      if (a === 'carNature') {
        this.$site.showCarList({ carNature: b.data.code })
      } else if (a === 'carStatus') {
        this.$site.showCarList({ carStatus: b.data.code })
      } else if (a === 'insurance') {
        if (b.seriesName.indexOf('交强险') !== -1) {
          this.$site.showCarList({ carStatus: 1, jqxInsuranceMonth: b.dataIndex + 1, noStatus7: true })
        } else if (b.seriesName.indexOf('商业险') !== -1) {
          this.$site.showCarList({ carStatus: 1, syxInsuranceMonth: b.dataIndex + 1, noStatus7: true })
        }
      } else if (a === 'yearlyInspect') {
        this.$site.showCarList({ carStatus: 1, yearlyInspectMonth: b.dataIndex + 1, noStatus7: true })
      }
    }
  }
}
</script>

<style lang="less" scoped></style>
